/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/listening-fatigue/'], ['en', 'https://www.hear.com/resources/hearing-aids/listening-fatigue/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/listening-fatigue/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/listening-fatigue/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "listening-fatigue-why-hearing-loss-is-exhausting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#listening-fatigue-why-hearing-loss-is-exhausting",
    "aria-label": "listening fatigue why hearing loss is exhausting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Listening Fatigue: Why Hearing Loss Is Exhausting"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you feel exhausted after talking with people, you might want to have your hearing checked out. Listening fatigue affects millions of Americans and contributes to increased stress. When you have hearing loss, just thinking about having a conversation in a noisy environment can be stressful. What many people don’t realize is that hearing loss forces your brain to work harder than it should, so something as simple as talking with a friend can leave you feeling exhausted. When you have to constantly strain to follow conversations or watch TV, everyday activities feel more like a burden than a blessing. In this article, we explore the relationship between hearing loss and exhaustion, and what you can do about it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-listening-fatigue",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-listening-fatigue",
    "aria-label": "what is listening fatigue permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is listening fatigue?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to Ian Noon, Head of Policy and Research at the National Deaf Children’s Society in the United Kingdom, hearing loss is “like doing jigsaws, Sudoku and Scrabble all at the same time.” Hearing loss-related listening fatigue is a condition caused by the increased effort used to listen and understand. As previously mentioned, something as simple as a conversation at a coffee shop can trigger the symptoms. Think about all the activities that require you to listen closely like attending family gatherings, church, meetings or watching TV. It takes a lot of energy to hear clearly and understand every word when you have hearing loss. For instance, many people compensate for their hearing loss by doing the following:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Concentrating really hard to hear and understand"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Asking people to repeat themselves frequently"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reading a speaker’s lips"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Guessing what someone said or trying to construct meaning out of half-heard words and sentences"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-exactly-does-listening-make-you-tired",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-exactly-does-listening-make-you-tired",
    "aria-label": "why exactly does listening make you tired permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why exactly does listening make you tired?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you can’t hear well, your brain has to work harder to process, interpret and give meaning to sounds. This causes the brain to be slower applying the information or putting that brainpower towards other activities. Needless to say, after a full day straining to hear, you’ll not only feel mentally drained but also physically exhausted. Some common signs of listening fatigue include…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Increased stress"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Low energy"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sleepiness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty concentrating"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reduced work performance"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Changes in mood"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-you-do-about-listening-fatigue",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-you-do-about-listening-fatigue",
    "aria-label": "what can you do about listening fatigue permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What can you do about listening fatigue?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you suspect that you or a loved one is experiencing listening fatigue, the first thing you should do is consult a hearing care professional. A hearing test will help determine if hearing loss is the culprit. In the case of hearing loss, hearing aids might be the best solution to combat listening fatigue. Hearing aids make it easier to hear sounds and understand speech in different environments. This reduces the amount of energy spent on listening and communicating. In addition, many of today’s hearing aids come with features that help reduce listening effort by automatically reducing background noise and clarifying the sounds you want to hear. If you haven’t signed up for a hearing aid trial yet, we highly recommend you do so today. Just click the button below to reserve your slot."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
